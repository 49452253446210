@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "poppins";
  src: local("poppins"),
    url("./fonts/poppins/Poppins-Regular.ttf") format("truetype");
}

body {
  overflow-y: overlay;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.overflow-y-auto {
  overflow-y: overlay;
}

::-webkit-scrollbar,
.overflow-auto::-webkit-scrollbar,
.overflow-y-auto::-webkit-scrollbar {
  background-color: transparent;
  height: 8px;
  width: 8px;
}

::-webkit-scrollbar-track,
.overflow-auto::-webkit-scrollbar-track,
.overflow-y-auto::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb,
.overflow-auto::-webkit-scrollbar-thumb,
.overflow-y-auto::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  background-color: #6582b366;
  border-radius: 8px;
  /* border: 2px transparent solid; */
}

:active::-webkit-scrollbar-thumb,
:hover::-webkit-scrollbar-thumb,
.overflow-auto:active::-webkit-scrollbar-thumb,
.overflow-auto:hover::-webkit-scrollbar-thumb,
.overflow-y-auto:active::-webkit-scrollbar-thumb,
.overflow-y-auto:hover::-webkit-scrollbar-thumb {
  background-color: #6481b3;
}

::-webkit-scrollbar-thumb:hover,
.overflow-auto::-webkit-scrollbar-thumb:hover,
.overflow-y-auto::-webkit-scrollbar-thumb:hover {
  background-color: #4C638C;
}

::-webkit-scrollbar-button,
::-webkit-scrollbar-corner,
.overflow-auto::-webkit-scrollbar-button,
.overflow-auto::-webkit-scrollbar-corner,
.overflow-y-auto::-webkit-scrollbar-button,
.overflow-y-auto::-webkit-scrollbar-corner {
  display: none;
}

.upload-list-inline .ant-upload-list-item {
  float: left;
  width: 200px;
  margin-inline-end: 8px;
}

.ant-upload-list-item-container {
  transition: none !important;
}

.ant-upload-rtl.upload-list-inline .ant-upload-list-item {
  float: right;
}
